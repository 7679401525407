<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'microsoft-kleinevents',
  primary_color: '#871A1A',
  secondary_color: '#F2904D',
  header: {
    background: {
      image: `/projekt/microsoft-kleinevents/elbworx-projekt-microsoft_kleinevents-header-1556.webp`,
    },
    project: 'Microsoft Kleinevents',
    headline: 'Klein, aber fein!',
    info_card: {
      description:
        'Eventdesign mit einer Vielzahl an Gesichtern, welches eine große Portion Kreativität und Professionalität fordert.',
      client: 'Microsoft',
      roles: ['Illustration', 'Veranstaltungsdesign'],
      project_duration: '2012 bis 2022',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="!text-primary">Herausforderung</h3>

      <p>
        Zusammen mit unseren Kollegen des e-teams realisierten wir über die Jahre hinweg große als
        auch kleine Events für Microsoft. In dieser Referenz widmen wir uns speziell den
        Kleinevents. Diese sind über die Jahre zu einer beträchtlichen Anzahl gewachsen. Das e-team
        kümmert sich um Eventplanung und das Geschehen vor Ort. Wir sind für die grafische Umsetzung
        zuständig.
      </p>
      <p>
        Eine Besonderheit für uns bietet das Microsoft Headquarter in München, welches in seinem
        Atrium bespielbare digitale Anzeigen besitzt. Für diese Flächen überlegen wir uns bei jedem
        neuen Event eine passende Bespielung. Unser weiterer Aufgabenbereich beschränkt sich auf die
        grafische Unterstützung, zu welcher RollUps, bedruckbare T-Shirts, KeyVisuals, kleinere
        Printprodukte und Illustrationen gehören.
      </p>
    </template>

    <Grid class="items-start">
      <div class="md:col-span-2 md:col-start-6 md:mt-[104px]">
        <Text class="text-center md:text-left">
          <h3 class="!text-[#5D4E97]">Hackathon</h3>
        </Text>
      </div>
      <div class="flex h-full items-end md:col-span-5 md:row-start-1 md:row-end-3">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-hoodie-1920.webp`"
        />
      </div>
      <div class="md:col-span-7 md:col-start-6 md:row-span-2 md:row-start-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-chandeliers-1211.webp`"
        />
      </div>
      <div class="md:col-span-4 md:col-end-6 md:row-start-3">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-rollup-edit-960.webp`"
        />
      </div>
    </Grid>
    <Grid class="items-start">
      <div class="col-span-6 md:row-span-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-chandeliers2-1430.webp`"
        />
      </div>

      <div class="col-span-6 md:col-start-7">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-shirt-1920.webp`"
        />
      </div>
      <div class="col-span-5 md:col-start-7 md:row-start-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-rollup2-edit-1920.webp`"
        />
      </div>
    </Grid>

    <Padding class="mt-16">
      <Text>
        <h3 class="!text-[#00396D]">AI Factory for BizApps Illustration</h3>
      </Text>
    </Padding>
    <div>
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-aifactory-3840.webp`"
      />
    </div>

    <Grid class="mt-16 items-end">
      <div class="col-span-7 md:col-start-6">
        <Padding class="md:contents">
          <Text>
            <h3 class="!text-[#BF3715]">Gemeinsam gewinnen einfach machen</h3>
          </Text>
        </Padding>
      </div>
      <div class="col-span-5">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-company_meeting1.webp`"
        />
      </div>
      <div class="col-span-7">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-company_meeting2-1884.webp`"
        />
      </div>
    </Grid>
    <Grid class="items-start">
      <div class="col-span-5">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-company_meeting3-1920.webp`"
        />
      </div>
      <div class="col-span-6">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-company_meeting4-1920.webp`"
        />
      </div>
    </Grid>
    <Grid class="items-start">
      <div class="col-span-5 col-start-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-company_meeting5-1920.webp`"
        />
      </div>
      <div class="col-span-6">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mst-kleinprojekte-company_meeting6-1920.webp`"
        />
      </div>
    </Grid>
  </Project>
</template>
